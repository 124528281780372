import { useEffect, useState } from 'react';
import './App.css';
import { format } from 'date-fns';

const URL = 'https://gorsele-onroad-back.herokuapp.com/api/anonymous/';

const App = () => {
  const [status, setStatus] = useState(undefined);
  const [drivers, setDrivers] = useState(undefined);
  const [selectedDriver, setSelectedDriver] = useState(undefined);
  const [driverData, setDriverData] = useState(undefined);

  const loadDriverData = async (e) => {
    e.preventDefault();
    setDriverData(undefined);
    let driverID = null;
    for (let d = 0; d < drivers.length; d++) {
      if (drivers[d].alias === selectedDriver) {
        driverID = drivers[d]._id;
        break;
      }
    }
    if (!driverID) return alert('Geen driver ID gevonden');

    const driverRes = await fetch(`${URL}tester-driverdata/${driverID}`);
    const dData = await driverRes.json();
    if (!dData.success)
      return alert('Geen gegevens van chauffeur kunnen ophalen!');
    setDriverData(dData.result);
  };

  const loadPage = async () => {
    const statusRes = await fetch(`${URL}tester-status`);
    const statusData = await statusRes.json();
    setStatus(statusData);

    if (statusData.success) {
      const driverRes = await fetch(`${URL}tester-drivers`);
      const driverData = await driverRes.json();
      if (driverData.success) {
        setDrivers(driverData.result);
      }
    }
  };

  useEffect(() => {
    loadPage();
  }, []);
  return (
    <div
      className="App"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <header style={{ marginTop: 6, fontSize: 18, fontWeight: 'bold' }}>
        Gorsele OnRoad Tester
      </header>
      <p>
        <b>Status van back-end server</b>
        <br />
        {status?.success && status?.result?.length ? (
          <span style={{ color: 'green' }}>
            Back-end actief en gegevens van order {status.result[0].ordernr}{' '}
            kunnen opgehaald worden.
          </span>
        ) : !status ? (
          'Geen gegevens'
        ) : (
          <span style={{ color: 'red' }}>Back-end niet bereikbaar</span>
        )}
      </p>
      {drivers?.length ? (
        <form
          style={{ display: 'flex', flexDirection: 'column', maxWidth: 320 }}
          onSubmit={loadDriverData}
        >
          <p>
            Gegevens inladen van chauffeur zoals die in de app zichtbaar zouden
            zijn na aanmelden.
          </p>
          <select
            onChange={(e) => {
              setDriverData(undefined);
              setSelectedDriver(e.currentTarget.value);
            }}
          >
            <option>Selecteer chauffeur</option>
            {drivers.map((driver, d) => (
              <option key={d}>{driver.alias}</option>
            ))}
          </select>
          {selectedDriver ? (
            <button
              style={{
                backgroundColor: '#24A799',
                padding: 6,
                borderRadius: 15,
                border: 'none',
                boxShadow: 'none',
                marginTop: 6,
              }}
              type="submit"
            >
              Laad gegevens voor {selectedDriver}
            </button>
          ) : null}
        </form>
      ) : null}
      {driverData ? (
        <div
          style={{ backgroundColor: 'lightgray', padding: 10, marginTop: 6 }}
        >
          <p>
            <strong>{selectedDriver}</strong>
          </p>
          <p>
            <strong>Versie van app</strong>
            <br />
            {driverData.tracks?.length
              ? driverData.tracks[0].appVersion
              : 'Geen gegevens'}
          </p>
          <p>
            <strong>Aantal orders in app</strong>
            <br />
            {driverData.orders?.length || 0}
          </p>
          <p>
            <strong>Gegevens van orders</strong>
          </p>
          <ul style={{ textAlign: 'left' }}>
            {driverData.orders?.length
              ? driverData.orders.map((order, o) => (
                  <li key={o}>
                    Order {order.ordernr} - {order.route.routeString.short}
                  </li>
                ))
              : null}
          </ul>
          <p>
            <strong>Laatste tracking gegevens van app</strong>
          </p>
          <ul style={{ textAlign: 'left' }}>
            {driverData.tracks?.length
              ? driverData.tracks.map((track, t) => (
                  <li key={t}>
                    Tijd {format(track.time, 'dd/MM/yyyy HH:mm')} - Nummerplaat{' '}
                    {track.licensePlate} - Adres:{' '}
                    {track.fullAddress || 'Geen adres'}
                  </li>
                ))
              : null}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default App;
